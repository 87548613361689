import React from "react"
import Img from "gatsby-image"
import SEO from "../components/seo"
import {Link} from "gatsby"

const disqusShortname = "shopper"

const BlogDetails = data => (
  <React.Fragment>
    <SEO
      title={data?.data?.contentfulBlogs?.title}
      keywords={[`gatsby`, `ecommerce`, `react`, `contentFul`, `Snipcart`]}
    />
    <div className="blogs-page">
      <div className="container">
        <div className="post-thumbnail">
          <Img sizes={data?.data?.contentfulBlogs?.featureImage?.fluid} />
        </div>
        <div className="container">
          <div className="post-details">
            <h2 className="title">{data?.data?.contentfulBlogs?.title}</h2>
            <div className="post-date">
              <i className="fas fa-calendar-alt"></i>
              {data?.data?.contentfulBlogs?.publicData}
            </div>
            <div className="author">
              <Img sizes={data?.data?.contentfulBlogs?.author?.photo?.fixed} />
              <strong className="name">
                {data?.data?.contentfulBlogs?.author?.name}
              </strong>
            </div>
            <div className="blog-main-container"
              dangerouslySetInnerHTML={{
                __html:
                  data?.data?.contentfulBlogs?.description.childMarkdownRemark.html,
              }}
            />
            <div className="back-button-container">
            <Link to={'/blogs'}>
              <button className="back-button">Back</button>
            </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
)

export default BlogDetails

export const query = graphql`
  query BlogDetailsQuery($slug: String!) {
    contentfulBlogs(slug: { eq: $slug }) {
      id
      title
      slug
      publicData(formatString: "MMMM D, YYYY")
      author {
        name
        photo {
          fixed(width: 50, height: 50) {
            width
            height
            src
            srcSet
          }
        }
      }
      description {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 250)
        }
      }
      featureImage {
        fluid(quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`
